import { Box, Container, Flex, useDisclosure, useMediaQuery } from '@chakra-ui/react'
import { captureException } from '@sentry/react'
import { Spinner } from '@wanda-space/noelle'
import { CreateListingRequestBodyDto } from '@wanda-space/types'
import {
  createListingForStorage,
  postListingImage,
  postListingPrimaryImage,
  updateListingItemImage,
} from 'api-client/lib/routes/listing'
import { WarningModalContent } from 'components/Modals/WarningModal/WarningModal'
import { Routes as routes } from 'consts'
import { useAppSelector } from 'hooks/useAppSelector'
import { Stepable } from 'interfaces'
import { ListingForPreview } from 'interfaces/listing'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { generatePath, useNavigate } from 'react-router'
import { useAppDispatch } from 'reduxStore'
import {
  getListingFromStorageState,
  getSelectedItemsFromStorageToSell,
} from 'reduxStore/ducks/sell/selectors'
import { resetAll } from 'reduxStore/ducks/sell/sellFlow'
import { fetchUser } from 'reduxStore/ducks/user/user'
import { ForYourInformationBox } from 'routes/BuySellFlow/common/ForYourInformationBox'
import { InfoNotification } from 'routes/BuySellFlow/common/InfoNotification'
import { ListingPreview } from 'routes/BuySellFlow/common/ListingPreview'
import { useListingConfig } from 'routes/BuySellFlow/common/hooks'
import { convertToOre, getListingAmountDetails } from 'utils'
import { getListingItemsFromItem } from 'utils/item'

export const ListingCreateConfirmForStorage = ({ onNextStep, nextPath }: Stepable) => {
  const listingDetails = useAppSelector(getListingFromStorageState)
  const storageItems = useAppSelector(getSelectedItemsFromStorageToSell)
  const [isLoading, setIsLoading] = useState(false)
  const { formatMessage } = useIntl()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [isLargerThansmall] = useMediaQuery('(min-width: 480px)')
  const { isOpen, onClose } = useDisclosure()
  const {
    data: listingConfig,
    isInitialLoading: isListingConfigLoading,
    error,
  } = useListingConfig(storageItems?.[0]?.countryCode ?? undefined)

  const goToPublish = (id: string) => {
    setIsLoading(false)
    dispatch(resetAll())
    const nextPathWithSimpleId = nextPath && generatePath(nextPath, { simpleId: id })
    onNextStep({ nextPath: nextPathWithSimpleId, replace: true })
  }

  const handleCreateListing = async () => {
    const {
      name,
      price,
      description,
      images,
      showOwnerName,
      conditionType,
      dimensionLength,
      dimensionHeight,
      dimensionWidth,
      dimensionFreeText,
      extraDescriptiveInfo,
    } = listingDetails
    const storageItemIds = storageItems.map(({ id }) => id)
    const mapPayload: CreateListingRequestBodyDto = {
      name,
      price: convertToOre(price.amount),
      currency: price.currency,
      storageItems: storageItemIds as [string, ...string[]],
      description: description,
      showOwnerName,
      conditionType,
      dimensionLength,
      dimensionHeight,
      dimensionWidth,
      dimensionFreeText,
      extraDescriptiveInfo,
    }

    const listing = await createListingForStorage(mapPayload)
    await dispatch(fetchUser()).unwrap()

    setIsLoading(true)

    const hasImages = images && images.length > 0

    if (hasImages) {
      try {
        await handleUploadImageForListing(images, listing.id)
        await handleUploadListingImageForItem(images, listing.id, storageItemIds)
      } catch (error) {
        captureException(error)
        setIsLoading(false)
      }
    }

    goToPublish(listing.simpleId)
  }

  const handleUploadImageForListing = async (files: string[], listingId: string) => {
    return Promise.all(
      files.map(async (file, index) => {
        const { imageId } = await postListingImage({ listingId, file })
        if (index === 0) {
          await postListingPrimaryImage(listingId, imageId)
        }
        return imageId
      })
    )
  }

  const handleUploadListingImageForItem = async (
    files: string[],
    listingId: string,
    itemIds: string[]
  ) => {
    const [primaryImage] = files
    if (primaryImage) {
      for (const itemId of itemIds) {
        await updateListingItemImage(listingId, itemId, primaryImage)
      }
    }
  }

  const handleDeleteListing = () => {
    dispatch(resetAll())
    navigate(routes.Sell)
  }

  if (error) {
    throw error
  }

  if (isListingConfigLoading || !listingConfig) {
    return <Spinner />
  }

  // update name of selected items as they will be updated in the backend later
  // update image for the preview, item image will be updated along with listing image after confirming
  const selectedItemsWithListingNamePrefix = storageItems.map((item) => {
    const image = !item.image ? listingDetails.images?.[0] : undefined
    const _item = getListingItemsFromItem(item)
    return {
      ..._item,
      listingName: `${listingDetails.name} : ${item.name}`,
      image,
    }
  })

  const { sellerAmount, feeFlat, feePercent } = getListingAmountDetails(
    listingDetails.price,
    listingConfig
  )

  return (
    <>
      <Flex direction={['column', 'row']}>
        <Container pt="0">
          {!isLargerThansmall && (
            <Box mb={2}>
              <InfoNotification
                handleClickOnNextStep={handleCreateListing}
                isBuyableListing={false}
                sellingFromHome={false}
                isLoading={isLoading}
              />
            </Box>
          )}
          <ListingPreview
            listing={listingDetails as unknown as ListingForPreview}
            listingConfig={listingConfig}
            selectedItems={selectedItemsWithListingNamePrefix}
          />
        </Container>{' '}
        <Container maxW={['100%', 'xs']} p={['auto', '0']}>
          <Flex gap={4} direction="column">
            {isLargerThansmall && (
              <InfoNotification
                handleClickOnNextStep={handleCreateListing}
                isBuyableListing={false}
                sellingFromHome={false}
                isLoading={isLoading}
              />
            )}
            <ForYourInformationBox
              sellerAmount={sellerAmount}
              feeFlat={feeFlat}
              feePercent={feePercent}
              currency={listingDetails.price.currency}
            />
          </Flex>
        </Container>
      </Flex>
      <WarningModalContent
        title={formatMessage({ id: 'delete.sales.page.confirmation.title' })}
        description={formatMessage(
          { id: 'delete.sales.page.confirmation.description' },
          { name: listingDetails.name, bold: (value) => <b>{value}</b> }
        )}
        onClose={onClose}
        isOpen={isOpen}
        handleClickYes={handleDeleteListing}
      />
    </>
  )
}
