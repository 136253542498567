import {
  Box,
  Button,
  Collapse,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { captureException } from '@sentry/react'
import { ChevronDownIcon, CloseIcon, OptionItem, useWandaToast } from '@wanda-space/noelle'
import { WandaToastOptions } from '@wanda-space/noelle/dist/hooks/useToast'
import { ConditionType, ListingDimensionTypes, type ListingResponseDto } from '@wanda-space/types'
import {
  deleteListingImage,
  updateListingConditionType,
  updateListingDescription,
  updateListingDimension,
  updateListingExtraDescriptiveInfo,
  updateListingName,
  updateListingPrice,
} from 'api-client'
import { RemovableImage } from 'components/Item/Image/ItemImage'
import { Field, FieldProps, Form, Formik } from 'formik'
import { isEmpty, isNil } from 'ramda'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import type { ListingImageReference } from 'routes/BuySellFlow/common/hooks'
import { convertToOre, sanitizeAmount } from 'utils'
import {
  ConditionSelector,
  DimensionFields,
  ExtraDescriptiveInfoFields,
  ListingDescription,
} from './CreateListingFields'

type ListingDimension = {
  length: ListingResponseDto['dimensionLength']
  width: ListingResponseDto['dimensionWidth']
  height: ListingResponseDto['dimensionHeight']
  freeText: ListingResponseDto['dimensionFreeText']
}

interface Props {
  listingId: string
  name: string
  description: string
  price: number
  currency: string
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
  onEdited: () => void
  imageReferences: ListingImageReference[]
  conditionType?: ConditionType
  dimensions?: ListingDimension
  extraDescriptiveInfo?: ListingResponseDto['extraDescriptiveInfo']
}

export const toNumberOrStringOrUndefined = (dimension: number | string | null | undefined) => {
  if (dimension === null) return undefined
  return dimension
}

interface EditListingFormValues {
  name: string
  price: number
  dimensionLength?: number
  dimensionHeight?: number
  dimensionWidth?: number
  dimensionFreeText?: string
  conditionType?: ConditionType
  description: string
  extraDescriptiveInfo?: ListingResponseDto['extraDescriptiveInfo']
}

export const EditListingModal = ({
  isOpen,
  onClose,
  onEdited,
  name,
  dimensions,
  conditionType,
  extraDescriptiveInfo,
  description,
  price,
  currency,
  listingId,
  imageReferences,
}: Props) => {
  const [isCollapsed, setIsCollapsed] = useState(true)

  const { formatMessage } = useIntl()
  const [listingImageIdsToDelete, setListingImageIdsToDelete] = useState<string[]>([])

  const toast = useWandaToast({
    durationInMilliSecond: 9000,
    position: 'bottom',
  })

  const toastError: WandaToastOptions = {
    status: 'error',
    title: formatMessage({ id: 'buy.sell.update.error.title' }),
    description: formatMessage({ id: 'phrase.something.went.wrong' }),
    variant: 'subtle',
  }

  const handleUpdateName = async (listingName: EditListingFormValues['name']) => {
    try {
      if (name !== listingName) {
        await updateListingName(listingName, listingId)
      }
    } catch (error) {
      toast(toastError)
      captureException(error)
    }
  }

  const handleUpdatePrice = async (listingPrice: EditListingFormValues['price']) => {
    try {
      const updatedPrice = convertToOre(listingPrice)
      if (price !== updatedPrice) {
        await updateListingPrice(updatedPrice, listingId)
      }
    } catch (error) {
      toast(toastError)
      captureException(error)
    }
  }

  const handleUpdateDescription = async (
    listingDescription: EditListingFormValues['description']
  ) => {
    try {
      if (description !== listingDescription) {
        await updateListingDescription(listingDescription, listingId)
      }
    } catch (error) {
      toast(toastError)
      captureException(error)
    }
  }

  const handleDeleteListingImages = async () => {
    try {
      await Promise.all(
        listingImageIdsToDelete.map((imageId) => deleteListingImage(listingId, imageId))
      )
    } catch (error) {
      toast(toastError)
      captureException(error)
    }
  }

  const handleListingDimension = async (
    dimensionType: string,
    originalDimension?: number | string | null,
    newDimension?: number | string | null
  ) => {
    if (originalDimension === undefined || newDimension === undefined) return

    try {
      if (originalDimension !== newDimension) {
        await updateListingDimension(listingId, dimensionType, newDimension)
      }
    } catch (error) {
      toast(toastError)
      captureException(error)
    }
  }

  const handleUpdateListingConditionType = async (
    listingConditionType: EditListingFormValues['conditionType']
  ) => {
    try {
      if (conditionType !== listingConditionType && listingConditionType) {
        await updateListingConditionType(listingId, listingConditionType)
      }
    } catch (error) {
      toast(toastError)
      captureException(error)
    }
  }

  const handleUpdateListingExtraDescriptiveInfo = async (
    listingExtraDescriptiveInfo: EditListingFormValues['extraDescriptiveInfo']
  ) => {
    try {
      if (JSON.stringify(extraDescriptiveInfo) !== JSON.stringify(listingExtraDescriptiveInfo)) {
        await updateListingExtraDescriptiveInfo(listingId, listingExtraDescriptiveInfo)
      }
    } catch (error) {
      toast(toastError)
      captureException(error)
    }
  }

  const handleUpdateListing = async ({
    name,
    description,
    price,
    dimensionLength,
    dimensionHeight,
    dimensionWidth,
    dimensionFreeText,
    conditionType,
    extraDescriptiveInfo,
  }: EditListingFormValues) => {
    try {
      const promises = []

      promises.push(handleUpdateName(name))
      promises.push(handleUpdateDescription(description))
      promises.push(handleUpdatePrice(price))
      promises.push(handleDeleteListingImages())

      promises.push(
        handleListingDimension(
          ListingDimensionTypes.dimensionHeight,
          dimensions?.height,
          dimensionHeight
        )
      )
      promises.push(
        handleListingDimension(
          ListingDimensionTypes.dimensionWidth,
          dimensions?.width,
          dimensionWidth
        )
      )
      promises.push(
        handleListingDimension(
          ListingDimensionTypes.dimensionLength,
          dimensions?.length,
          dimensionLength
        )
      )
      promises.push(
        handleListingDimension(
          ListingDimensionTypes.dimensionFreeText,
          dimensions?.freeText,
          dimensionFreeText
        )
      )

      promises.push(handleUpdateListingConditionType(conditionType))
      promises.push(handleUpdateListingExtraDescriptiveInfo(extraDescriptiveInfo))

      await Promise.all(promises)

      toast({
        status: 'success',
        title: formatMessage({ id: 'buy.sell.update.success.title' }),
        description: formatMessage({ id: 'buy.sell.update.success.description' }, { name }),
        variant: 'subtle',
      })

      onEdited()
      onClose()
    } catch (error) {
      captureException(error)
    }
  }

  const handleRemoveListingImage = async (imageId: string) => {
    const updatedImageIdsToDelete = new Set([...listingImageIdsToDelete, imageId])
    setListingImageIdsToDelete(Array.from(updatedImageIdsToDelete))
  }

  const imageUrls = imageReferences.map((imageRef) => imageRef.imageUrl)

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent width="auto" display="inline-block">
        <ModalHeader
          pb={0}
          borderRadius={8}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Text>{formatMessage({ id: 'info.buy.sell.edit.sales.page' })}</Text>
          <IconButton
            aria-label="close button"
            icon={<CloseIcon w="4" h="4" />}
            onClick={onClose}
            backgroundColor="purple.200"
            size="sm"
            position="absolute"
            top="2"
            right="2"
          />
        </ModalHeader>

        <Formik
          initialValues={{
            name,
            price: sanitizeAmount(price), // convert from øre
            description,
            dimensionLength: dimensions?.length || undefined,
            dimensionHeight: dimensions?.height || undefined,
            dimensionWidth: dimensions?.width || undefined,
            dimensionFreeText: dimensions?.freeText || undefined,
            conditionType: conditionType ?? ConditionType.USED,
            extraDescriptiveInfo: extraDescriptiveInfo,
          }}
          onSubmit={handleUpdateListing}
        >
          {({ isSubmitting, resetForm }) => (
            <Form>
              <ModalBody>
                <Field name="name">
                  {({ field, form }: FieldProps<string, EditListingFormValues>) => (
                    <FormControl
                      isInvalid={
                        isEmpty(form.errors.name) === false &&
                        isNil(form.errors.name) === false &&
                        form.touched.name
                      }
                    >
                      <FormLabel mb={2}>{formatMessage({ id: 'word.title' })}</FormLabel>
                      <Input
                        {...field}
                        placeholder={formatMessage({ id: 'word.title' })}
                        type="text"
                        size="lg"
                        mb={2}
                      />
                      <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <ListingDescription images={imageUrls} />

                <Field isValid name="price">
                  {({ field, form }: FieldProps<number, EditListingFormValues>) => (
                    <FormControl isInvalid={!!(form.errors.price && form.touched.price)} mb={8}>
                      <FormLabel mb={2}>{formatMessage({ id: 'word.price' })}</FormLabel>
                      <InputGroup size="lg" mb={2}>
                        <InputLeftAddon>{currency}</InputLeftAddon>
                        <Input
                          {...field}
                          min={0}
                          value={field.value === undefined ? '' : field.value}
                          placeholder={formatMessage({
                            id: 'phrase.for.example.short',
                          })}
                          type="number"
                        />
                      </InputGroup>
                      <FormErrorMessage>{form.errors.price}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>

                <Box my={4}>
                  <Grid templateColumns="repeat(auto-fill, minmax(40%, 1fr))" gap={2}>
                    {imageReferences
                      .filter((imageRef) => !listingImageIdsToDelete.includes(imageRef.imageId))
                      .map((image) => (
                        <RemovableImage
                          key={image.imageId}
                          src={image.imageUrl}
                          imageUrls={imageUrls}
                          onRemoveImage={() => handleRemoveListingImage(image.imageId)}
                        />
                      ))}
                  </Grid>
                </Box>

                <ConditionSelector />
                <DimensionFields />

                <Box className="extraDescriptiveInfo">
                  <Flex justifyContent="space-between" alignItems="center">
                    <Text mt={4} fontWeight="semibold">
                      {formatMessage({ id: 'word.extraDescriptiveInfo' })}
                    </Text>

                    <Icon
                      as={ChevronDownIcon}
                      boxSize={6}
                      onClick={() => setIsCollapsed(!isCollapsed)}
                      cursor="pointer"
                      transform={isCollapsed ? '' : 'rotate(180deg)'}
                    />
                  </Flex>

                  <Collapse in={!isCollapsed}>
                    <InputGroup gap={2} flexDirection="column" size="lg" mb={2}>
                      <ExtraDescriptiveInfoFields />
                    </InputGroup>
                  </Collapse>
                </Box>
              </ModalBody>
              <ModalFooter>
                <Button
                  mt={2}
                  mr={2}
                  isLoading={isSubmitting}
                  type="button"
                  colorScheme="gray"
                  onClick={() => resetForm()}
                >
                  {formatMessage({ id: 'word.reset' })}
                </Button>
                <Button
                  mt={2}
                  isLoading={isSubmitting}
                  type="submit"
                  colorScheme="purple"
                  backgroundColor="purple.500"
                >
                  {formatMessage({ id: 'word.save' })}
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  )
}
