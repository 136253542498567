import { captureException } from '@sentry/react'
import { SupportedCities, SupportedCountries } from '@wanda-space/types'
import { fetchPostalCodes } from 'api-client/lib/routes/postalCodes'
import { useAppSelector } from 'hooks/useAppSelector'
import { DEFAULT_CITY, DEFAULT_COUNTRY, DEFAULT_POSTAL_CODE, Locale as LocaleKey } from 'i18n'
import React, { ReactNode, useContext, useEffect, useState } from 'react'
import { IntlProvider as Provider } from 'react-intl'
import { useSearchParams } from 'react-router-dom'
import { useAppDispatch } from 'reduxStore'
import { setLanguage, setPostalCode } from 'reduxStore/ducks/ui'
import { setLocale } from 'reduxStore/ducks/user/user'

import { useActiveUserLocation } from '../../hooks/useActiveUserLocation'
import { useTranslations } from '../../hooks/useTranslations'
import { polyfillDateformat, polyfillNumberFormat, polyfillPluralrules } from './polyfills'

interface Props {
  changeLanguage: (locale: string) => void
  locale: string
}

interface IntlProps {
  children: ReactNode
}

export const useDateLocale = () => {
  const [localeFunction, setLocaleFunction] = useState<Locale | undefined>()
  const locale = useAppSelector((state) => state.ui.language)

  useEffect(() => {
    localefunction[locale]().then((module) => {
      setLocaleFunction(module.default)
    })
  }, [locale])

  return localeFunction
}

export const IntlContext = React.createContext({} as Props)
export const useIntlContext = () => {
  return useContext(IntlContext)
}

const localefunction: Record<LocaleKey, () => Promise<{ default: Locale }>> = {
  [LocaleKey.English]: async () => import('date-fns/locale/en-GB'),
  [LocaleKey.Norwegian]: async () => import('date-fns/locale/nb'),
  [LocaleKey.Swedish]: async () => import('date-fns/locale/sv'),
}

const IntlProvider = ({ children }: IntlProps) => {
  const dispatch = useAppDispatch()
  const [params, setSearchParams] = useSearchParams()
  const activeLocale = useAppSelector((state) => state.ui.language)

  const activeUserLocation = useActiveUserLocation()

  const queryParamsLocale = params.get('locale')
  const queryParamsCountry = params.get('country')?.toUpperCase()
  const queryParamsPostalCode = params.get('postalCode')
  const queryShowKeys = params.has('showI18nKeys')

  useEffect(() => {
    polyfillPluralrules(activeLocale)
    polyfillNumberFormat(activeLocale)
    polyfillDateformat(activeLocale)
  }, [activeLocale])

  useEffect(() => {
    if (
      queryParamsLocale &&
      typeof queryParamsLocale === 'string' &&
      queryParamsLocale !== activeLocale
    ) {
      dispatch(setLanguage(queryParamsLocale))
    }
  }, [queryParamsLocale])

  function setDefaultLocation() {
    dispatch(
      setPostalCode({
        postalCode: DEFAULT_POSTAL_CODE,
        city: DEFAULT_CITY,
        country: DEFAULT_COUNTRY,
      })
    )
    setSearchParams(new URLSearchParams())
  }

  useEffect(() => {
    if (
      activeUserLocation.postalCode === DEFAULT_POSTAL_CODE &&
      queryParamsPostalCode &&
      queryParamsCountry
    ) {
      fetchPostalCodes({
        postalCode: queryParamsPostalCode,
        country: queryParamsCountry as SupportedCountries,
      }).then((response) => {
        if (response.length >= 1) {
          dispatch(
            setPostalCode({
              postalCode: response[0].postalCode,
              city: response[0].city as SupportedCities,
              country: response[0].country as SupportedCountries,
            })
          )

          setSearchParams(new URLSearchParams())
        }
      })
    }
  }, [queryParamsPostalCode, queryParamsCountry])

  const translations = useTranslations()

  return (
    <IntlContext.Provider
      value={{
        locale: activeLocale,
        changeLanguage: (locale: string) => {
          dispatch(setLocale(locale))
          dispatch(setLanguage(locale))
        },
      }}
    >
      <Provider
        key={activeLocale}
        locale={activeLocale}
        messages={queryShowKeys ? {} : translations?.data}
        onError={(error) => {
          if (error.code !== 'MISSING_DATA' && error.code !== 'MISSING_TRANSLATION') {
            captureException(error, {
              fingerprint: getIntlErrorFingerprint(error.code, error.message),
            })
          }
        }}
      >
        {children}
      </Provider>
    </IntlContext.Provider>
  )
}

function getIntlErrorFingerprint(code: string, message: string) {
  if (
    code === 'FORMAT_ERROR' &&
    message.includes('Intl.PluralRules is not available in this environment.')
  ) {
    return ['intl-error', code, 'Intl.PluralRules is not available in this environment.']
  }
  return ['intl-error', code, message.split('\n')[0]]
}

export { IntlProvider }
