import { OrderType, ServiceLevelType } from '@wanda-space/types'

import { getKeyForServiceLevel } from './orderline'

export const getKeyForOrderType = (orderType: OrderType): 'delivery' | 'pickUp' => {
  const orderTypeToKey: Record<OrderType, 'pickUp' | 'delivery'> = {
    [OrderType.PICK_UP]: 'pickUp',
    [OrderType.DELIVERY]: 'delivery',
    [OrderType.CUSTOMER_COLLECTS]: 'delivery',
    [OrderType.CUSTOMER_DROP_OFF]: 'delivery',
  }
  return orderTypeToKey[orderType]
}

export const getCarryingMethodLabel = (
  orderType: OrderType,
  serviceLevelType: ServiceLevelType | undefined
) => {
  const serviceLevelTypeKey = getKeyForServiceLevel(serviceLevelType ?? ServiceLevelType.CURBSIDE)
  const orderTypeKey = getKeyForOrderType(orderType)

  return `carryingMethod.${serviceLevelTypeKey}.${orderTypeKey}.title`
}
