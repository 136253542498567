import { useQuery } from '@tanstack/react-query'
import { SupportedCountries } from '@wanda-space/noelle'
import {
  fetchListingConfig,
  fetchListingItems,
  getListingBySimpleId,
  getListingImages,
} from 'api-client'
import {
  add,
  getListingAmountDetails,
  getListingImageUrl,
  listingToPrice,
  productToPrice,
} from 'utils'

export interface ListingImageReference {
  imageUrl: string
  imageId: string
}

export function useListingImages(listingId?: string) {
  const listingImages = useQuery(
    ['listingImages', listingId],
    async () => {
      const fallback: ListingImageReference[] = []
      if (listingId) {
        const result = await getListingImages(listingId)
        const imagesIds = result.map((i) => i.split('/')[1])

        return imagesIds.map((imageId) => {
          return {
            imageId,
            imageUrl: getListingImageUrl({ listingId, imageId }),
          }
        })
      }
      return fallback
    },
    { enabled: Boolean(listingId) }
  )

  return listingImages
}

export function useListingConfig(countryCode?: SupportedCountries) {
  return useQuery(
    ['fetchListingConfig'],
    () => {
      if (countryCode) {
        return fetchListingConfig({ countryCode })
      }
    },
    {
      enabled: Boolean(countryCode),
    }
  )
}

export function useListingPreviewDetails(
  simpleId: string,
  {
    enableCondition = true,
    refetchInterval = false,
  }: { enableCondition?: boolean; refetchInterval?: number | false } = {
    enableCondition: true,
    refetchInterval: false,
  }
) {
  const listingResult = useQuery({
    queryKey: ['listing', simpleId],
    queryFn: () => getListingBySimpleId(simpleId),
    enabled: enableCondition,
    refetchInterval,
    retry: (failCount, status) => {
      if (status === 404) {
        return false
      }

      if (failCount >= 3) {
        return false
      }

      return true
    },
  })

  const listingId = listingResult.data?.id

  const listingItemsResult = useQuery(
    ['listingItems', listingId],
    async () => {
      if (listingId) {
        const listingItems = await fetchListingItems(listingId)
        return listingItems
      }
      return []
    },
    {
      enabled: Boolean(listingId),
    }
  )
  const listingImages = useListingImages(listingId)

  const serviceFeeDetails = listingResult.data
    ? getListingAmountDetails(listingToPrice(listingResult.data), listingResult.data)
    : undefined

  const storagePrice = add(
    ...(listingItemsResult.data?.map(({ storageProduct }) => productToPrice(storageProduct)) ?? [])
  )

  return {
    listingResult,
    listingItemsResult,
    listingImages,
    serviceFeeDetails,
    storagePrice,
    isSuccess: listingResult.isSuccess && listingItemsResult.isSuccess,
    isError: listingResult.isError || listingItemsResult.isError,
    isLoading: listingResult.isInitialLoading || listingItemsResult.isInitialLoading,
    error: listingResult.error || listingItemsResult.error,
  }
}
