import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Grid,
  GridItem,
  IconButton,
  Input,
  InputGroup,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Textarea,
} from '@chakra-ui/react'
import { useMutation } from '@tanstack/react-query'
import {
  ChevronDownIcon,
  OptionItem,
  WandaLogoShortIcon,
  WandaSpinner,
  useWandaToast,
} from '@wanda-space/noelle'
import { ConditionType } from '@wanda-space/types'
import { generateListingDescription } from 'api-client'
import { ApiError } from 'api-client/lib/api-client'
import { Field, FieldProps } from 'formik'
import { useAppSelector } from 'hooks/useAppSelector'
import { useFeatureFlags } from 'hooks/useFeatureFlags'
import { isEmpty, isNil } from 'ramda'
import React, { ReactNode, useState } from 'react'
import { useIntl } from 'react-intl'
import { ListingFormValues } from 'reduxStore/ducks/sell/sellFlow'

export const ConditionSelector = () => {
  const { formatMessage } = useIntl()
  return (
    <Field isValid name="conditionType">
      {({ field, form }: FieldProps<number, ListingFormValues>) => (
        <FormControl isInvalid={!!form.errors.conditionType}>
          <Grid templateColumns={['repeat(2, 1fr)', 'repeat(2, 1fr)']} columnGap="4" rowGap={4}>
            {Object.keys(ConditionType).map((ct) => {
              const title = `${ct.toLowerCase()}.title`
              const description = `${ct.toLowerCase()}.description`
              return (
                <GridItem key={ct} colSpan={1}>
                  <OptionItem
                    {...field}
                    w="100%"
                    minHeight="70px"
                    active={form.values.conditionType === ct}
                    text={formatMessage({ id: title })}
                    description={formatMessage({ id: description })}
                    icon={null as unknown as JSX.Element}
                    onClick={() => form.setFieldValue(field.name, ct)}
                    textProps={{ fontWeight: 500 }}
                    flexShrink={1}
                  />
                </GridItem>
              )
            })}
          </Grid>
          <FormErrorMessage>{form.errors.conditionType}</FormErrorMessage>
        </FormControl>
      )}
    </Field>
  )
}

export const DimensionFields = () => {
  const { formatMessage } = useIntl()
  const handleDimensionFieldChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    { field, form }: FieldProps<number, ListingFormValues>
  ) => {
    const newValue = event.target.value
    const actualValue = newValue === '' || newValue === '0' ? undefined : Number(newValue)
    form.setFieldValue(field.name, actualValue)
  }

  return (
    <Box my={6}>
      <Flex justifyContent="space-between">
        <FormLabel position="absolute">
          {formatMessage({ id: 'word.dimensions' })} ({formatMessage({ id: 'word.cm' })})
        </FormLabel>
        <Accordion allowMultiple width="100%">
          <Field name="dimensionFreeText">
            {({ field }: FieldProps<string, ListingFormValues>) => (
              <AccordionItem border="none">
                <AccordionButton p={0} _hover={{ backgroundColor: 'none' }}>
                  <FormLabel
                    width="100%"
                    textAlign="right"
                    fontWeight="normal"
                    color="gray.500"
                    textDecoration="underline"
                  >
                    {formatMessage({ id: 'different.size' })}
                  </FormLabel>
                </AccordionButton>
                <AccordionPanel px={0} width="100%">
                  <InputGroup size="lg" width="100%">
                    <Input
                      {...field}
                      size="lg"
                      width="100%"
                      placeholder={formatMessage({ id: 'dimension.freeText.placeholder' })}
                    />
                  </InputGroup>
                </AccordionPanel>
              </AccordionItem>
            )}
          </Field>
        </Accordion>
      </Flex>
      <Flex gap="2">
        <Field name="dimensionLength">
          {(fieldProps: FieldProps<number, ListingFormValues>) => {
            return (
              <FormControl
                isInvalid={Boolean(
                  fieldProps.form.errors.dimensionLength && fieldProps.form.touched.dimensionLength
                )}
              >
                <Input
                  {...fieldProps.field}
                  placeholder={formatMessage({ id: 'word.length' })}
                  size="lg"
                  type="number"
                  min={0}
                  value={!fieldProps.field.value ? '' : fieldProps.field.value}
                  onChange={(e) => handleDimensionFieldChange(e, fieldProps)}
                />
                <FormErrorMessage>{fieldProps.form.errors.dimensionLength}</FormErrorMessage>
              </FormControl>
            )
          }}
        </Field>
        <Field name="dimensionWidth">
          {(fieldProps: FieldProps<number, ListingFormValues>) => (
            <FormControl
              isInvalid={Boolean(
                fieldProps.form.errors.dimensionWidth && fieldProps.form.touched.dimensionWidth
              )}
            >
              <Input
                {...fieldProps.field}
                type="number"
                min={0}
                placeholder={formatMessage({ id: 'word.width' })}
                size="lg"
                value={!fieldProps.field.value ? '' : fieldProps.field.value}
                onChange={(e) => handleDimensionFieldChange(e, fieldProps)}
              />
              <FormErrorMessage>{fieldProps.form.errors.dimensionWidth}</FormErrorMessage>
            </FormControl>
          )}
        </Field>
        <Field name="dimensionHeight">
          {(fieldProps: FieldProps<number, ListingFormValues>) => (
            <FormControl
              isInvalid={Boolean(
                fieldProps.form.errors.dimensionHeight && fieldProps.form.touched.dimensionHeight
              )}
            >
              <Input
                {...fieldProps.field}
                type="number"
                min={0}
                value={!fieldProps.field.value ? '' : fieldProps.field.value}
                placeholder={formatMessage({ id: 'word.height' })}
                size="lg"
                onChange={(e) => handleDimensionFieldChange(e, fieldProps)}
              />
              <FormErrorMessage>{fieldProps.form.errors.dimensionHeight}</FormErrorMessage>
            </FormControl>
          )}
        </Field>
      </Flex>
    </Box>
  )
}

export const ExtraDescriptiveInfoFields = ({ formLabel }: { formLabel?: ReactNode }) => {
  const { formatMessage } = useIntl()
  return (
    <>
      {formLabel}

      <Field name="extraDescriptiveInfo.brand">
        {({ field, form }: FieldProps<string, ListingFormValues>) => (
          <FormControl
            isInvalid={Boolean(
              form.errors.extraDescriptiveInfo?.brand && form.touched.extraDescriptiveInfo?.brand
            )}
            mb={6}
          >
            <FormLabel mb={2} fontWeight="normal">
              {formatMessage({ id: 'word.brand' })}
            </FormLabel>
            <Input
              {...field}
              value={field.value === undefined ? '' : field.value}
              size="lg"
              placeholder={formatMessage({ id: 'brand.input.helptext' })}
            />
          </FormControl>
        )}
      </Field>
      <Field name="extraDescriptiveInfo.model">
        {({ field, form }: FieldProps<string, ListingFormValues>) => (
          <FormControl
            isInvalid={Boolean(
              form.errors.extraDescriptiveInfo?.model && form.touched.extraDescriptiveInfo?.model
            )}
            mb={6}
          >
            <FormLabel mb={2} fontWeight="normal">
              {formatMessage({ id: 'word.model' })}
            </FormLabel>
            <Input
              {...field}
              size="lg"
              placeholder={formatMessage({ id: 'model.input.helptext' })}
            />
          </FormControl>
        )}
      </Field>
      <Field name="extraDescriptiveInfo.year">
        {({ field, form }: FieldProps<string, ListingFormValues>) => (
          <FormControl
            isInvalid={Boolean(
              form.errors.extraDescriptiveInfo?.year && form.touched.extraDescriptiveInfo?.year
            )}
            mb={6}
          >
            <FormLabel mb={2} fontWeight="normal">
              {formatMessage({ id: 'word.year' })}
            </FormLabel>
            <Input
              {...field}
              size="lg"
              placeholder={formatMessage({ id: 'year.input.helptext' })}
            />
          </FormControl>
        )}
      </Field>
      <Field name="extraDescriptiveInfo.material">
        {({ field, form }: FieldProps<string, ListingFormValues>) => (
          <FormControl
            isInvalid={Boolean(
              form.errors.extraDescriptiveInfo?.material &&
                form.touched.extraDescriptiveInfo?.material
            )}
            mb={6}
          >
            <FormLabel mb={2} fontWeight="normal">
              {formatMessage({ id: 'word.material' })}
            </FormLabel>
            <Input
              {...field}
              size="lg"
              placeholder={formatMessage({ id: 'material.input.helptext' })}
            />
          </FormControl>
        )}
      </Field>
      <Field name="extraDescriptiveInfo.colour">
        {({ field, form }: FieldProps<string, ListingFormValues>) => (
          <FormControl
            isInvalid={Boolean(
              form.errors.extraDescriptiveInfo?.colour && form.touched.extraDescriptiveInfo?.colour
            )}
            mb={6}
          >
            <FormLabel mb={2} fontWeight="normal">
              {formatMessage({ id: 'word.colour' })}
            </FormLabel>
            <Input
              {...field}
              size="lg"
              placeholder={formatMessage({ id: 'colour.input.helptext' })}
            />
          </FormControl>
        )}
      </Field>
    </>
  )
}

export const ListingDescription = ({
  helperText,
  images,
}: { helperText?: ReactNode; images: string[] }) => {
  const { formatMessage } = useIntl()
  const featureFlagsResult = useFeatureFlags()
  const descriptionGeneratorMutation = useMutation(generateListingDescription)
  const toast = useWandaToast()
  const [tone, setTone] = useState('casual')
  const locale = useAppSelector((state) => state.ui.language)

  return (
    <Field name="description">
      {({ field, form }: FieldProps<string, ListingFormValues>) => (
        <FormControl>
          <FormLabel
            mb={2}
            display="flex"
            justifyContent="space-between"
            alignItems={['initial', 'center']}
            flexDirection={['column', 'row']}
          >
            {formatMessage({ id: 'word.description' })}
            {featureFlagsResult.data?.ENABLE_DESCRIPTION_GENERATOR ? (
              <Menu>
                <ButtonGroup isAttached={true} size="sm" colorScheme="purple">
                  <MenuButton as={IconButton} icon={<ChevronDownIcon />} />
                  <Button
                    onClick={() =>
                      descriptionGeneratorMutation.mutate(
                        {
                          title: form.values.name,
                          tone: tone,
                          file: images[0],
                          locale,
                        },
                        {
                          onSuccess: (data) => form.setFieldValue('description', data.description),
                          onError: (error) => {
                            toast({
                              title: formatMessage({
                                id: 'error.failed-to-generate.title',
                              }),
                              description: formatMessage({
                                id:
                                  (error as ApiError).localizationKey() ??
                                  'error.generate-description.general',
                              }),
                              status: 'error',
                            })
                          },
                        }
                      )
                    }
                    rightIcon={
                      descriptionGeneratorMutation.isLoading ? (
                        <WandaSpinner />
                      ) : (
                        <WandaLogoShortIcon />
                      )
                    }
                  >
                    {formatMessage(
                      { id: 'selling.form.generate.button.text' },
                      { tone: formatMessage({ id: `word.tone.${tone}` }) }
                    )}
                  </Button>
                </ButtonGroup>
                <MenuList>
                  <MenuItem onClick={() => setTone('casual')}>
                    {formatMessage({ id: 'word.tone.casual' })}
                  </MenuItem>
                  <MenuItem onClick={() => setTone('formal')}>
                    {formatMessage({ id: 'word.tone.formal' })}
                  </MenuItem>
                  <MenuItem onClick={() => setTone('poetic')}>
                    {formatMessage({ id: 'word.tone.poetic' })}
                  </MenuItem>
                </MenuList>
              </Menu>
            ) : null}
          </FormLabel>
          <Textarea
            {...field}
            placeholder={formatMessage({ id: 'selling.form.description.placeholder' })}
            size="lg"
            mb={2}
          />
          {helperText}
          <FormErrorMessage>{form.errors.description}</FormErrorMessage>
        </FormControl>
      )}
    </Field>
  )
}
